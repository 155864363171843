import React from "react"

import { Section, LargeCardContainer, SEO } from "components"

import StandardTemplate from "templates/StandardTemplate"
import Posts from "components/Posts"

const BlogPage = props => {
  return (
    <StandardTemplate>
      <SEO title="Blog" />
      <h1>Blog</h1>

      <Section title={""}>
        <LargeCardContainer>
          <Posts />
        </LargeCardContainer>
      </Section>
    </StandardTemplate>
  )
}

export default BlogPage
